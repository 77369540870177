import React, { useState } from 'react';
import CodeField from './CodeField';
import { checkDigits } from '../helpers/common';
import { message } from 'antd';

const ConfirmEmail = ({ confirmMailLink, confirmedMail, setConfirmedMail, setConfirmMailLink }) => {
	const [code, setCode] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const successMessage = () => {
		messageApi.open({
			type: 'success',
			content: 'Email подтвержден!',
		});
	};

	const checkCode = event => {
		console.log(event.target.value);
		if (event.target.value === '1111') {
			setConfirmedMail(true);
			setConfirmMailLink(false);
			successMessage();
		}
	};

	return (
		<div style={{ marginTop: -20, marginBottom: 10 }}>
			{contextHolder}
			{confirmMailLink && !code && (
				<span
					onClick={() => {
						setCode(true);
					}}
					style={{ color: '#1677ff', cursor: 'pointer' }}
				>
					Подтвердить
				</span>
			)}
			{confirmedMail && <span style={{ color: 'green' }}>Email подтвержден</span>}
			{code && !confirmedMail && (
				<CodeField label='Введите код' style={{ width: 100 }} onKeyPress={checkDigits} onChange={checkCode} />
			)}
		</div>
	);
};

export default ConfirmEmail;
