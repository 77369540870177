// -----------------------------------------------------
// Функция для переводов loco
// -----------------------------------------------------

export const __ = function (key, loco = {}) {
	if (loco[key]) {
		return loco[key];
	} else {
		return key;
	}
};

// -----------------------------------------------------
// Алиас для функции перевода.
// Сделано для обратной совместимости
// -----------------------------------------------------

export const translate = __;

// -----------------------------------------------------
// Форматирует число в соответствии с локалью и валютой
// на выходе из числа 252 получим по умолчанию строку - 252,00 ₽
// -----------------------------------------------------

export const formatNumber = (num, locale, currency, digits) => {
	let formatter = new Intl.NumberFormat(locale || 'ru', {
		style: 'currency',
		currency: currency || 'RUB',
		minimumFractionDigits: digits || 2,
	});

	num = parseFloat(Number(num));

	if (num === 'undefined') {
		throw new Error('First function argument is undefined');
	}

	if (Number.isNaN(num)) {
		throw new Error('First function argument is NaN');
	}

	return formatter.format(num || 0);
};

// -----------------------------------------------------
// Из строки которая начинается с числа парсит числа
// из строки 252,00 ₽ вернет 252
// -----------------------------------------------------

export const parseNumbers = str => {
	if (typeof str === 'string') {
		let arr = str.split('');
		let result = [];

		for (let i = 0; i < arr.length; i++) {
			const item = arr[i];

			if (!Number.isNaN(Number(item)) && item !== ' ') {
				result.push(item);
			}

			if (item === '.' || item === ',') {
				return parseInt(result.join(''), 10);
			}
		}
	} else {
		throw new Error('[*** parseNumbers: Value must be a string ***]');
	}
};

// -----------------------------------------------------
// Функция для формирования списка времени для selectbox
// -----------------------------------------------------

export const createTimeList = () => {
	const addValue = time => {
		let timeStr = (i < 10 ? '0' + i : String(i)) + time;
		result.push({
			value: timeStr,
			label: timeStr,
		});
	};

	const result = [];
	let i = 0;
	while (i < 24) {
		addValue(':00');
		addValue(':30');

		i++;
	}

	return result;
};

// -----------------------------------------------------
// Функция пропускает только числа. Работает с onKeyPress
// -----------------------------------------------------

export const checkDigits = e => {
	if (!/[0-9]/.test(e.key)) {
		e.preventDefault();
	}
};

// -----------------------------------------------------
// Функция пропускает только дробные числа. Работает с onKeyPress
// -----------------------------------------------------

export const checkDouble = e => {
	// [\.,]{0,1} было так но линтер ругался
	if (!/^[0-9]*[\\.,]{0,1}[0-9]*$/.test(e.key)) {
		e.preventDefault();
	}
};

// -----------------------------------------------------
// Форматирование номера телефона из числа в строку с маской
// -----------------------------------------------------

export const formatPhoneNumber = phone => {
	if (typeof phone === 'number') {
		phone = phone.toString();
	}

	if (typeof phone === 'string' && phone) {
		return `+${phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')}`;
	} else {
		return '';
	}
};

// -----------------------------------------------------
// Функция делает первый символ заглавным
// -----------------------------------------------------

export const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
