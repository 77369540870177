import { Form } from 'antd';
import React from 'react';

const AppForm = ({ children, ...props }) => {
	return (
		<Form
			name='basic'
			layout='vertical'
			size='large'
			scrollToFirstError={{
				behavior: 'smooth',
				inline: 'nearest',
			}}
			style={{
				width: 420,
				padding: 20,
				background: '#fff',
				borderRadius: 10,
			}}
			autoComplete='off'
			{...props}
		>
			{children}
		</Form>
	);
};

export default AppForm;
