import { Form, Input } from 'antd';
import React from 'react';
import { mailValidator, validationRequired } from './validators';

const MailField = ({ readOnly, suffix, ...props }) => {
	return (
		<Form.Item
			label='Email'
			name='email'
			layout='vertical'
			rules={[...validationRequired(), ...mailValidator()]}
			{...props}
		>
			<Input suffix={suffix} readOnly={readOnly} />
		</Form.Item>
	);
};

export default MailField;
