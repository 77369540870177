import { Form } from 'antd';
import React from 'react';
import { phoneValidator, validationRequired } from './validators';
import { MaskedInput } from 'antd-mask-input';

const PhoneField = ({ onChange, suffix, readOnly, ...props }) => {
	return (
		<Form.Item
			label='Телефон'
			name='phone'
			layout='vertical'
			rules={[...validationRequired(), ...phoneValidator()]}
			{...props}
		>
			<MaskedInput
				suffix={suffix}
				readOnly={readOnly}
				onChange={onChange}
				style={{ padding: '8px 10px' }}
				mask={
					//  https://imask.js.org/guide.html#masked-pattern
					'+7 (000)-000-00-00'
				}
			/>
		</Form.Item>
	);
};

export default PhoneField;
