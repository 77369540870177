import { message, Typography } from 'antd';
import '../src/styles/styles.css';
import React, { useEffect, useState } from 'react';
import './style.css';
import { CheckCircleTwoTone } from '@ant-design/icons';
import AppForm from './templates/AppForm';
import PhoneField from './templates/PhoneField';
import MailField from './templates/MailField';
import PasswordField from './templates/PasswordField';
import ConfirmPasswordField from './templates/ConfirmPasswordField';
import NameField from './templates/NameField';
import { validationRequired } from './templates/validators';
import DateField from './templates/DateField';
import RegistrationButton from './templates/RegistrationButton';
import SupportNotification from './templates/SupportNotification';
import Agreement from './templates/Agreement';
import Citizenship from './templates/Citizenship';
import ConfirmEmail from './templates/ConfirmEmail';
import ConfirmPhone from './templates/ConfirmPhone';
import ModalConfirmPhone from './templates/ModalConfirmPhone';
const { Title } = Typography;

const MAIL_REG_EXP =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_REG_EXP = /(?=(^([^\d]*?\d){11}$))/;

function App() {
	const successMessage = () => {
		messageApi.open({
			type: 'success',
			content: 'Вы успешно прошли регистрацию!',
		});
	};

	const onFinish = values => {
		console.log('Success:', values);
		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);
			successMessage();
		}, 2000);
	};
	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	const checkMail = event => {
		console.log(event.target.value);
		if (MAIL_REG_EXP.test(event.target.value)) {
			setConfirmMailLink(true);
		} else {
			setConfirmMailLink(false);
		}
	};

	const checkPhone = event => {
		if (event.inputType && PHONE_REG_EXP.test(event.target.value)) {
			console.log(event.target.value);
			setConfirmPhoneLink(true);
		} else {
			setConfirmPhoneLink(false);
		}
	};

	const required = validationRequired();

	const [messageApi, contextHolder] = message.useMessage();
	const [isLoading, setIsLoading] = useState(false);
	const [confirmMailLink, setConfirmMailLink] = useState(false);
	const [confirmPhoneLink, setConfirmPhoneLink] = useState(false);
	const [confirmedMail, setConfirmedMail] = useState(false);
	const [confirmedPhone, setConfirmedPhone] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		console.log(confirmedPhone);
	}, [confirmedPhone]);

	return (
		<div style={{ display: 'grid', justifyContent: 'center', marginTop: 20 }}>
			{contextHolder}
			<Title level={2} style={{ textAlign: 'center' }}>
				Регистрация
			</Title>
			<AppForm onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<PhoneField
					suffix={confirmedPhone ? <CheckCircleTwoTone twoToneColor='#52c41a' /> : null}
					readOnly={confirmedPhone}
					onChange={event => {
						checkPhone(event);
					}}
				/>
				<ConfirmPhone
					confirmPhoneLink={confirmPhoneLink}
					confirmedPhone={confirmedPhone}
					setConfirmedPhone={setConfirmedPhone}
					setConfirmPhoneLink={setConfirmPhoneLink}
					setModalOpen={setModalOpen}
				/>
				<MailField
					suffix={confirmedMail ? <CheckCircleTwoTone twoToneColor='#52c41a' /> : null}
					readOnly={confirmedMail}
					onChange={event => {
						checkMail(event);
					}}
				/>
				<ConfirmEmail
					confirmMailLink={confirmMailLink}
					confirmedMail={confirmedMail}
					setConfirmedMail={setConfirmedMail}
					setConfirmMailLink={setConfirmMailLink}
				/>
				<PasswordField />
				<ConfirmPasswordField />
				<NameField label='Фамилия' name='lastName' rules={required} />

				<NameField label='Имя' name='firstName' rules={required} />
				<NameField label='Отчество' name='middleName' />
				<Citizenship />
				<DateField label='Дата рождения' name='birthDate' rules={required} />
				<Agreement />
				<SupportNotification />
				<RegistrationButton isLoading={isLoading} />
			</AppForm>

			<ModalConfirmPhone
				setModalOpen={setModalOpen}
				isModalOpen={isModalOpen}
				setConfirmedPhone={setConfirmedPhone}
				setConfirmPhoneLink={setConfirmPhoneLink}
			/>
		</div>
	);
}

export default App;
