import { Form, Input } from 'antd';
import React from 'react';
import { passValidator } from './validators';

const PasswordField = ({ ...props }) => {
	return (
		<Form.Item label='Пароль' validateFirst name='password' rules={passValidator()} {...props}>
			<Input.Password />
		</Form.Item>
	);
};

export default PasswordField;
