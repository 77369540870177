import React from 'react';

const ConfirmPhone = ({ confirmPhoneLink, confirmedPhone, setModalOpen }) => {
	return (
		<div style={{ marginTop: -20, marginBottom: 10 }}>
			{confirmPhoneLink && (
				<span
					onClick={() => {
						setModalOpen(true);
					}}
					style={{ color: '#1677ff', cursor: 'pointer' }}
				>
					Подтвердить номер телефона
				</span>
			)}
			{confirmedPhone && <span style={{ color: 'green' }}>Телефон подтвержден</span>}
		</div>
	);
};

export default ConfirmPhone;
