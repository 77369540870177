import { Checkbox, Form } from 'antd';
import React from 'react';
import { validateAgreement } from './validators';

const Agreement = () => {
	return (
		<Form.Item name='agreement' valuePropName='checked' rules={validateAgreement()}>
			<Checkbox>
				{/* eslint-disable-next-line */}Я даю свое{' '}
				<a href='#'>согласие на обработку персональных данных и присоединяюсь к электронному взаимодействию</a> и
				{/* eslint-disable-next-line */}
				ознакомлен(а) <a href='#'>с политикой конфиденциальности</a>
			</Checkbox>
		</Form.Item>
	);
};

export default Agreement;
