import { Form, Input } from 'antd';
import React from 'react';

const CodeField = ({ onChange, ...props }) => {
	return (
		<Form.Item layout='vertical' {...props}>
			<Input style={{ padding: '8px 10px' }} onChange={onChange} />
		</Form.Item>
	);
};

export default CodeField;
