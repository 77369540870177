export const validationRequired = () => {
	return [
		{
			required: true,
			message: 'Обязательное поле!',
		},
	];
};

export const phoneValidator = () => {
	return [
		{
			pattern: /(?=(^([^\d]*?\d){11}$))/,
			message: 'Неправильно введен номер телефона',
		},
	];
};

export const mailValidator = () => {
	return [
		{
			pattern:
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: 'Email указан неверно!',
		},
	];
};

export const passValidator = () => {
	return [
		{
			required: true,
			message: 'Обязательное поле!',
		},
		{
			pattern: /^[a-z0-9$@!%*#?&]+$/i,
			message:
				'Пароль должен состоять из букв латинского алфавита (A-z), цифр (0-9) и символов ((пробел)!"#$%&()*+,-./:;<=>?@[]^`{|}~)',
		},
		{
			min: 6,
			message: 'Пароль должен состоять минимум из 6 символов',
		},
	];
};

export const validateConfirmPassword = () => {
	return [
		{
			required: true,
			message: 'Повторите пароль!',
		},
		({ getFieldValue }) => ({
			validator(_, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Пароли не совпадают!'));
			},
		}),
	];
};

export const validateAgreement = () => {
	return [
		{
			validator: (_, value) =>
				value ? Promise.resolve() : Promise.reject(new Error('Подтвердите согласие на обработку персональных данных!')),
		},
	];
};
