import { DatePicker, Form } from 'antd';
import React from 'react';

const DateField = ({ ...props }) => {
	return (
		<Form.Item layout='vertical' {...props}>
			<DatePicker
				style={{ width: '100%' }}
				format={{
					format: 'DD.MM.YYYY',
					type: 'mask',
				}}
			/>
		</Form.Item>
	);
};

export default DateField;
