import { Button, Form } from 'antd';
import React from 'react';

const RegistrationButton = ({ isLoading }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Form.Item style={{ marginTop: 50 }}>
				<Button type='primary' htmlType='submit' loading={isLoading}>
					Отправить
				</Button>
			</Form.Item>
		</div>
	);
};

export default RegistrationButton;
