import { Form, Input } from 'antd';
import React from 'react';

const NameField = ({ ...props }) => {
	return (
		<Form.Item layout='vertical' {...props}>
			<Input />
		</Form.Item>
	);
};

export default NameField;
