import { Form, Input } from 'antd';
import React from 'react';
import { validateConfirmPassword } from './validators';

const ConfirmPasswordField = () => {
	return (
		<Form.Item label='Повтор пароля' name='passwordAgain' dependencies={['password']} rules={validateConfirmPassword()}>
			<Input.Password />
		</Form.Item>
	);
};

export default ConfirmPasswordField;
