import { Button, Radio } from 'antd';
import React, { Fragment, useState } from 'react';

const WithoutIncome = ({ setScreen, setVariant }) => {
	const [value, setValue] = useState(1);

	return (
		<Fragment>
			<Radio.Group
				value={value}
				onChange={event => {
					setValue(event.target.value);
				}}
			>
				<Radio value={1}>
					<p style={{ marginBottom: 0 }}>
						<b>СМС - Код</b>
					</p>
					<p style={{ marginTop: 2, color: '#888' }}>На номер придет смс с кодом подтверждения</p>
				</Radio>
				<Radio value={3}>
					<p style={{ marginBottom: 0 }}>
						<b>Исходящий звонок</b>
					</p>
					<p style={{ marginTop: 2, color: '#888' }}>
						Позвоните по указанному номеру, с введенного номера телефона. Звонок для вас бесплатный, он сбросится
						автоматически, после этого ваши данные будут подтверждены
					</p>
				</Radio>
			</Radio.Group>
			<Button
				onClick={() => {
					setScreen(1);
					setVariant(value);
				}}
				style={{ marginTop: 10 }}
				type='primary'
				size='large'
				block
			>
				Продолжить
			</Button>
		</Fragment>
	);
};

export default WithoutIncome;
