import { Form, Input } from 'antd';
import React from 'react';
import { checkDigits } from '../helpers/common';
import { validationRequired } from './validators';

const ShowPassportVariant = ({ id }) => {
	if (id === 1) {
		return (
			<div style={{ display: 'flex', gap: 10 }}>
				<Form.Item label='Серия' name='series' rules={validationRequired()} layout='vertical'>
					<Input onKeyPress={checkDigits} />
				</Form.Item>
				<Form.Item label='Номер' name='number' rules={validationRequired()} layout='vertical'>
					<Input onKeyPress={checkDigits} />
				</Form.Item>
			</div>
		);
	} else {
		return (
			<Form.Item label='Номер документа' name='docNumber' rules={validationRequired()} layout='vertical'>
				<Input onKeyPress={checkDigits} />
			</Form.Item>
		);
	}
};

export default ShowPassportVariant;
