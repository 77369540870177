import React, { Fragment, useState } from 'react';
import { Button, Form, Input, Modal, Result, Typography } from 'antd';
import { ArrowLeftOutlined, LoadingOutlined, MailTwoTone, PhoneFilled } from '@ant-design/icons';
import { checkDigits } from '../helpers/common';
import WithoutSms from './RadioCases/WithoutSms';
import WithoutIncome from './RadioCases/WithoutIncome';
import WithoutOutcome from './RadioCases/WithoutOutcome';
const { Title } = Typography;

const messageStyle = {
	padding: '11px 15px',
	marginTop: 50,
	marginBottom: 5,
	background: 'rgb(243, 244, 245)',
	borderRadius: 5,
	textAlign: 'center',
};

const ModalConfirmPhone = ({ isModalOpen, setModalOpen, setConfirmedPhone, setConfirmPhoneLink, ...props }) => {
	const onClose = () => {
		setModalOpen(false);
		setScreen(1);
		setVariant(1);
	};

	const handleCode = event => {
		if (event.target.value === '1111') {
			setScreen(3);
		}
	};

	const selectRadio = variant => {
		switch (variant) {
			case 1:
				return <WithoutSms setScreen={setScreen} setVariant={setVariant} />;

			case 2:
				return <WithoutIncome setScreen={setScreen} setVariant={setVariant} />;

			case 3:
				return <WithoutOutcome setScreen={setScreen} setVariant={setVariant} />;
			default:
				break;
		}
	};

	const selectScreen = variant => {
		if (variant === 1) {
			return (
				<Fragment>
					<div style={{ ...messageStyle, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
						<MailTwoTone style={{ color: '#1677ff', fontSize: 20 }} />
						Отправили смс на номер <b>+79153655809</b>
					</div>
					<Form>
						<Form.Item layout='vertical' onKeyPress={checkDigits}>
							<Input size='large' placeholder='Введите код' onChange={handleCode} />
						</Form.Item>
					</Form>

					<div style={{ marginTop: 70, textAlign: 'center' }}>
						{/* {isExpired ? <Button type='link'>Отправить код повторно</Button> : <div>{timer}</div>} */}

						<div style={{ color: '#888' }}>1 мин 30 сек</div>

						<Button
							type='link'
							onClick={() => {
								setScreen(2);
							}}
						>
							Изменить способ подтверждения
						</Button>
					</div>
				</Fragment>
			);
		} else if (variant === 2) {
			return (
				<Fragment>
					<div style={messageStyle}>
						<PhoneFilled rotate={100} style={{ color: '#1677ff', fontSize: 20 }} /> Поступит звонок на номер{' '}
						<b>+79153655809</b>
					</div>
					<Form>
						<Form.Item layout='vertical' onKeyPress={checkDigits}>
							<Input size='large' placeholder='Введите код' onChange={handleCode} />
						</Form.Item>
					</Form>

					<div style={{ marginTop: 70, textAlign: 'center' }}>
						{/* {isExpired ? <Button type='link'>Отправить код повторно</Button> : <div>{timer}</div>} */}

						<div style={{ color: '#888' }}>1 мин 30 сек</div>

						<Button
							type='link'
							onClick={() => {
								setScreen(2);
							}}
						>
							Изменить способ подтверждения
						</Button>
					</div>
				</Fragment>
			);
		} else if (variant === 3) {
			return (
				<div>
					<div style={{ textAlign: 'center', color: '#888', marginTop: 30 }}>
						Как только мы получим звонок, форма обновится автоматически
					</div>
					<div
						style={{
							...messageStyle,
							marginTop: 15,
							marginBottom: 15,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 10,
						}}
					>
						<PhoneFilled rotate={100} style={{ color: '#1677ff', fontSize: 20 }} /> Ждем звонка с номера{' '}
						<b>+79153655809</b>
					</div>

					<div style={{ textAlign: 'center', marginBottom: 12 }}>Бесплатный звонок по номеру</div>

					<div style={{ textAlign: 'center' }}>
						<div style={{ fontSize: 17, fontWeight: 500, lineHeight: '22px', color: 'rgb(0, 122, 255)' }}>
							74996709976
						</div>
						<div style={{ color: 'rgb(252, 131, 45)', margin: '12px 0' }}>
							<LoadingOutlined style={{ fontSize: 20 }} />
							&nbsp;&nbsp;Обрабатываем Ваш звонок
						</div>
						<Button
							type='link'
							onClick={() => {
								setScreen(2);
							}}
						>
							Изменить способ подтверждения
						</Button>
					</div>
				</div>
			);
		}
	};

	const selectedContent = screen => {
		switch (screen) {
			case 1:
				return selectScreen(variant);
			case 2:
				return <Fragment>{selectRadio(variant)}</Fragment>;

			default:
				return (
					<Fragment>
						<Result status='success' title='Телефон успешно подтвержден!' />
						<Button
							type='primary'
							onClick={() => {
								setModalOpen(false);
								setScreen(1);
								setVariant(1);
								setConfirmedPhone(true);
								setConfirmPhoneLink(false);
							}}
							size='large'
							block
						>
							Отлично!
						</Button>
					</Fragment>
				);
		}
	};

	const [variant, setVariant] = useState(1);
	const [screen, setScreen] = useState(1);

	return (
		<Modal
			{...props}
			onCancel={onClose}
			footer={null}
			width={420}
			closable={screen !== 3 ? true : false}
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						marginRight: 30,
						margin: 'auto',
						alignItems: 'center',
						width: '96%',
						gap: 10,
					}}
				>
					{screen === 2 ? (
						<ArrowLeftOutlined
							style={{ fontSize: 24, color: '#ccc', position: 'absolute', left: 25 }}
							onClick={() => {
								setScreen(1);
							}}
						/>
					) : null}
					<Title style={{ marginTop: 0, textAlign: 'center', fontWeight: 700, width: '100%' }} level={3}>
						Подтверждение
						<br /> номера телефона
					</Title>
				</div>
			}
			open={isModalOpen}
		>
			{selectedContent(screen)}
		</Modal>
	);
};

export default ModalConfirmPhone;
