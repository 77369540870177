import { Form, Select } from 'antd';
import React, { Fragment, useState } from 'react';
import ShowPassportVariant from './ShowPassportVariant';

const Citizenship = () => {
	const [citizenshipId, setCitizenship] = useState(1);

	const handleChange = value => {
		setCitizenship(Number(value));
	};

	return (
		<Fragment>
			<Form.Item label='Документ, удостоверяющий личность' name='passport' layout='vertical'>
				<Select
					defaultValue={'1'}
					value={String(citizenshipId)}
					style={{ width: '100%' }}
					onChange={handleChange}
					options={[
						{
							value: '1',
							label: 'Паспорт',
						},
						{
							value: '2',
							label: 'Заграничный паспорт',
						},
					]}
				/>
			</Form.Item>

			<ShowPassportVariant id={citizenshipId} />
		</Fragment>
	);
};

export default Citizenship;
