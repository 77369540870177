import { Button, Radio } from 'antd';
import React, { Fragment, useState } from 'react';

const WithoutOutcome = ({ setScreen, setVariant }) => {
	const [value, setValue] = useState(1);

	return (
		<Fragment>
			<Radio.Group
				value={value}
				onChange={event => {
					setValue(event.target.value);
				}}
			>
				<Radio value={1}>
					<p style={{ marginBottom: 0 }}>
						<b>СМС - Код</b>
					</p>
					<p style={{ marginTop: 2, color: '#888' }}>На номер придет смс с кодом подтверждения</p>
				</Radio>
				<Radio value={2}>
					<p style={{ marginBottom: 0 }}>
						<b>Входящий звонок на Ваш номер</b>
					</p>
					<p style={{ marginTop: 2, color: '#888' }}>Вам поступит звонок. Нужно будет ввести 4 последние цифры</p>
				</Radio>
			</Radio.Group>

			<Button
				onClick={() => {
					setScreen(1);
					setVariant(value);
				}}
				style={{ marginTop: 10 }}
				type='primary'
				size='large'
				block
			>
				Продолжить
			</Button>
		</Fragment>
	);
};

export default WithoutOutcome;
