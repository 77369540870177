import { Alert } from 'antd';
import React from 'react';

const SupportNotification = () => {
	return (
		<Alert
			message={
				<div>
					Если у вас возникли технические сложности свяжитесь с нами по email:{' '}
					<a href='mailto:support@rudn.ru'>support@rudn.ru</a>
				</div>
			}
			type='info'
		/>
	);
};

export default SupportNotification;
